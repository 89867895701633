/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

@import "yet-another-react-lightbox/styles.css";

.auth-one-bg {
    background-image: url("../../../images/nft/marketplace.png");

    .bg-overlay {
        opacity: 0.6;
    }
}

.custom-col-3 {
    border-right: 1px solid $gray-300;
}

@media (min-width: 992px) {
    // lg
    .custom-col-3:nth-child(3n) {
        border-right: none;
    }
}

@media (max-width: 991.98px) {
    // sm
    .custom-col-3:nth-child(2n) {
        border-right: none;
    }
}

@media (max-width: 575.98px) {
    // xs
    .custom-col-3 {
        border-right: none;
    }
}

.log-menu-sidebar-scroll {
    height: calc(100vh - 275px);
    @media (max-width: 991.98px) {
        height: calc(100vh - 150px);
    }
}

.grid-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.table-responsive {
    &.dropdown-responsive {
        > table {
            .dropdown {
                position: static;

                > .dropdown-menu {
                    left: auto !important;
                    top: 0 !important;
                }
            }
        }
    }
}

.status-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    color: white;
    text-align: center;
    z-index: 9999;
    transition: top 0.5s ease;

    &.on {
        background-color: green;
        animation: slideOut 2s forwards;
    }

    &.off {
        background-color: red;
    }
}

@keyframes slideOut {
    0% {
        top: 0;
    }
    100% {
        top: -20px;
    }
}
